import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useForm, FormProvider } from "react-hook-form";
import * as data from "./data";
import { ColorButton, CheckBox } from "../../../../components/ui";
import CONFIG from "../../../../config";
import { Textarea } from "../../../../components/ui";
import { patterns } from "../../../../helpers/validatePattern";
import axios from "axios";
import {
  Wrapper,
  Title,
  Text,
  Form,
  ButtonWrapp,
  CheckBoxWrap,
  DashetBlock,
} from "../style";
import useAuth from "../../../../helpers/auth";
import abi from "./abis/MetaUnitWhitelistIDO.json";

const MetaUnitWhitelist = () => {
  const [isCheck, setIsCheck] = useState(false);
  const methods = useForm({ mode: "onChange" });
  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, web3, checkNetwork } = useAuth();

  const load = async () => {
    const { data } = await axios.get("https://eo85tkgldoq7kwt.m.pipedream.net");
    setList(data);
    setLoading(false);
  };

  const filter = (valid) => {
    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    const filtered = list
      .map((item) => {
        if (item.length === 3) {
          return { mail: item[1], address: item[2] };
        }
        return { mail: item[1], address: item[0] };
      })
      .filter((item) => web3.utils.isAddress(item.address) === valid)
      .map((item) => item.address)
      .filter((item) => item !== "Email" || item !== "Email Address")
      .filter(onlyUnique);
    return filtered;
  };

  useEffect(() => {
    load();
  }, []);

  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const toggle = () => {
    setIsCheck(!isCheck);
  };

  const onSubmit = async (data) => {
    try {
      data.addresses = data.addresses.replaceAll(" ", "").split(",");
      if (isCheck) data.addresses = [...data.addresses, ...filter(true)];
      const contract = new web3.eth.Contract(abi, CONFIG.REACT_APP_METAUNIT_WHITELIST_IDO_ADDRESS);
      
      await contract.methods
        .setWhiteList(data.addresses)
        .call({ from: user.get("ethAddress") });

      await contract.methods
        .setWhiteList(data.addresses)
        .send({ from: user.get("ethAddress") });

        reset();
      enqueueSnackbar("Success", { variant: "success" });
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <Text>{data.subtitle}</Text>
      <Text>{data.rules}</Text>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Textarea
            title="Contract address"
            name="addresses"
            placeholder="0x7B16E2f3C5B91120D0aFe56EB013886dd953a3c0, 0xc5f24190Fd0f0C0e60470987f71E09c2474e782D"
          />
          <ButtonWrapp>
            <ColorButton title="Submit" type="submit"></ColorButton>
          </ButtonWrapp>
        </Form>
      </FormProvider>
      <CheckBoxWrap>
        <CheckBox active={isCheck} onClick={toggle} />
        <span>
          Sync with metaunit whitelists from social networks (this data will be
          included to list automatically)
        </span>
      </CheckBoxWrap>
      {isCheck && (
        <DashetBlock>
          {loading ? (
            "Loading..."
          ) : (
            <>
              <Text>
                Valid data ({filter(true).length} items):{"\n\n"}
                {filter(true).join(",  ")}
              </Text>
              <Text>
                Invalid data ({filter(false).length} items):{"\n\n"}
                {filter(false).join(",  ")}
              </Text>
            </>
          )}
        </DashetBlock>
      )}
    </Wrapper>
  );
};

export default MetaUnitWhitelist;
