import styled from 'styled-components';

export const Wrapper = styled.footer`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: #313131;
  z-index: 2;
`;

export const Text = styled.p`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  color: #a3a6b6;
  text-align: center;
`;
