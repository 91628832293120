import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './layout';
import Home from './modules/home';
import ErrorPage from './modules/error';

const router = createBrowserRouter([
  {
    path: '',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/:tab',
        element: <Home />,
      },
    ],
  },
]);

const Router = () => <RouterProvider router={router} />;
export default Router;
