import Moralis from "moralis";
import CONFIG from "../config";

export const APP_ID = CONFIG.APP_ID;
export const SERVER_URL = CONFIG.SERVER_URL;

Moralis.initialize(APP_ID);
Moralis.serverURL = SERVER_URL;

export default Moralis;
