import CONFIG from "../../../../config";
import getPureText from "../../../../helpers/getPureText";

export const title = "NFT Incentives Management";

export const subtitle = `Here you can manage FirstMintIncentive and SecondMintIncentive Contract (first mint: ${getPureText(
  CONFIG.REACT_APP_METAUNIT_FM_INCENTIVE_ADDRESS
)}, second mint: ${getPureText(
  CONFIG.REACT_APP_METAUNIT_SM_INCENTIVE_ADDRESS
)})`;

export const submit = "Submit new contact to subscription";
export const remove = "Delete contacts from subscription";

export const rules = `\nRules:\n1. Each address must be an ether address (ex. ${
  CONFIG.EXAMPLE_ADDRESS
});\n2. Each address must be separated by a comma (ex. ${getPureText(
  CONFIG.EXAMPLE_ADDRESS
)}, ${getPureText(CONFIG.EXAMPLE_ADDRESS)}, ${getPureText(
  CONFIG.EXAMPLE_ADDRESS
)});\n3. In the case of successful execution, 1 transaction should be called and a pop-up with the inscription "Success" should appear.`;
