import React from 'react';
import { Container } from '../../components/containers';
import { Wrapper, Text } from './style';

const Footer = () => (
  <Wrapper>
    <Container>
      <Text>©2022 MetaPlayerOne. All rights reserved</Text>
    </Container>
  </Wrapper>
);

export default Footer;
