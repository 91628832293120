import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  font-weight: 400;
  font-size: 16px;
  color: #010101;
  padding: 8px 16px;
  transition: 0.4s;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  & > svg,
  img {
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }
  &:hover {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  }
`;
const PrimaryButton = ({ title, onClick, disabled, type, children }) => {
  return (
    <Wrapper
      className="primary-button"
      type={type || 'button'}
      onClick={onClick}
      disabled={disabled}
      locked={disabled}
    >
      {children}
      {title}
    </Wrapper>
  );
};
export default PrimaryButton;
