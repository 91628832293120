import React, { useEffect, useState } from "react";
import { ColorButton, OneForm } from "../../../../components/ui";
import useAuth from "../../../../helpers/auth";
import { Wrapper, Title, Text, ButtonWrapp, IncentivesContent } from "../style";
import abi from "./abis/incentives.json";
import * as data from "./data.js";
import erc20 from "./abis/ERC20.json";
import CONFIG from "../../../../config";
import { useSnackbar } from "notistack";

const DAOIncentives = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, loadingWeb3, web3 } = useAuth();
  const [balance, setBalance] = useState(0);

  const load = async () => {
    const contract = new web3.eth.Contract(
      erc20,
      CONFIG.REACT_APP_META_UNIT_ADDRESS
    );
    const data = await contract.methods
      .balanceOf(CONFIG.REACT_APP_METAUNIT_DAO_INCENTIVE_ADDRESS)
      .call();
    setBalance(Math.round(data / 10 ** 18));
  };

  const setCoeficient = (address) => async (data) => {
    const contract = new web3.eth.Contract(abi, address);
    await contract.methods
      .setCoeficient(BigInt(data.amount * 10 ** 18)) // eslint-disable-line
      .call({ from: user.get("ethAddress") });
    await contract.methods
      .setCoeficient(data.amount)
      .send({ from: user.get("ethAddress") });
  };

  const transfer = (address) => async (data) => {
    const contract = new web3.eth.Contract(
      erc20,
      CONFIG.REACT_APP_META_UNIT_ADDRESS
    );
    await contract.methods
      .transfer(address, BigInt(data.amount * 10 ** 18)) // eslint-disable-line
      .call({ from: user.get("ethAddress") });
    await contract.methods
      .transfer(address, BigInt(data.amount * 10 ** 18)) // eslint-disable-line
      .send({ from: user.get("ethAddress") });
  };

  const withdrawAll = async (address) => {
    const contract = new web3.eth.Contract(abi, address);
    await contract.methods.withdraw().call({ from: user.get("ethAddress") }); // eslint-disable-line
    await contract.methods.withdraw().send({ from: user.get("ethAddress") }); // eslint-disable-line
  };

  useEffect(() => {
    if (!loadingWeb3) load();
  }, [loadingWeb3]);

  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <Text>{data.subtitle}</Text>
      <Text>{data.rules}</Text>
      <Text>
        {"\n"}Amount of MEU on DAO Incentive Contract: {balance} MEU
      </Text>
      <IncentivesContent>
        <OneForm
          title="Set coeficient to Dao Incentive Contract (will be mul. 10^18)"
          name="amount"
          placeholder="Placeholder"
          rules={{ required: true }}
          submit={setCoeficient(
            CONFIG.REACT_APP_METAUNIT_DAO_INCENTIVE_ADDRESS
          )}
        />
      </IncentivesContent>
      <IncentivesContent>
        <OneForm
          title="Transfer erc20 tokens to Dao incentive contract (will be mul. 10^18)"
          name="amount"
          placeholder="Placeholder"
          rules={{ required: true }}
          submit={transfer(CONFIG.REACT_APP_METAUNIT_DAO_INCENTIVE_ADDRESS)}
        />
      </IncentivesContent>
      <IncentivesContent>
        <ButtonWrapp start>
          <ColorButton
            onClick={withdrawAll(
              CONFIG.REACT_APP_METAUNIT_DAO_INCENTIVE_ADDRESS
            )}
            title="Withdraw all metaunits from DAO Incentive Contract"
          />
        </ButtonWrapp>
      </IncentivesContent>
    </Wrapper>
  );
};

export default DAOIncentives;
