import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: block;
`;
export const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
  padding-bottom: 16px;
`;
export const Text = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #1a1919;
  white-space: pre-wrap;
  @media screen and (max-width: 1024px) {
    width: 100%;
    word-break: break-word;
  }
`;
export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 24px;
`;
export const ButtonWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.start ? "flex-start" : "center")};
  padding: 24px 0;
  gap: 24px;
  & > button {
    padding: 12px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    flex-wrap: wrap;
    & > button {
      width: 100%;
    }
  }
`;
export const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;

  & > span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1a1919;
    margin-left: 8px;
  }
`;
export const DashetBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
  border: 1px dashed #2154fc;
  border-radius: 10px;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;
export const IncentivesContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 0 24px;
  padding-top: 24px;
  .input-default-title {
    max-width: calc(100vw - 116px);
  }
  @media screen and (max-width: 767px) {
    gap: 0;
    padding-top: 16px;
  }
`;
export const InputList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: 0;
  }
`;
