import React from 'react';

const Icon = ({ fill = 'transparent', stroke = '' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill={fill}
    stroke={stroke}
    strokeWidth="1.66667"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M16.6667 5L7.50004 14.1667L3.33337 10"
      stroke={stroke}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Icon;
