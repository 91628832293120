import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { PrimaryButton } from "../../components/ui";
import getPureText from "../../helpers/getPureText";
import { Flex } from "../../components/containers";
import ImgLogo from "../../assets/icon/logo.svg";
import IconCopy from "../../components/icons/IconCopy";
import IconLogout from "../../components/icons/IconLogout";
import { tabs } from "../../modules/home/data";
import {
  Wrapper,
  Container,
  Logo,
  WrapBtn,
  Profile,
  Address,
  InfoWrap,
  DropDown,
} from "./style";
import useAuth from "../../helpers/auth";
import CONFIG from "../../config";
import { useSnackbar } from "notistack";
import Moralis from "../../const/moralis";
import { Desktop, Mobile } from "../../helpers/responsive";
import { IconInfo } from "../../components/icons";

const Header = () => {
  const {
    user,
    authenticate,
    balance,
    logout,
    changeNetwork,
    metaunitBalance,
  } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [active, setActive] = useState(false);
  const onClickLogo = () => {
    window.scrollTo(0, 0);
  };
  const copy = () => {
    setCopied(true);
    navigator.clipboard.writeText(user?.get("ethAddress"));
    setTimeout(() => setCopied(false), 3000);
  };
  const getCopied = () =>
    copied ? (
      "Copied"
    ) : (
      <>
        {getPureText(user?.get("ethAddress"))}
        <IconCopy />
      </>
    );

  useEffect(() => {
    if (pathname === "/") {
      navigate(`/${tabs[0].value}`);
    }
  }, [pathname]);

  const addMetaunit = async () => {
    try {
      await changeNetwork();
      await Moralis?.provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: CONFIG.REACT_APP_META_UNIT_ADDRESS,
            symbol: "MEU",
            decimals: 18,
            image: "https://etherscan.io/token/images/metaplayerone_32.png",
          },
        },
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Wrapper>
      <Container>
        <Logo onClick={onClickLogo}>
          <img src={ImgLogo} alt="MetaPlayer" />
        </Logo>
        {/* <PrimaryButton title="Add MetaUnit To metamask" onClick={addMetaunit} /> */}
        <Flex>
          {!!user ? (
            <Profile>
              <Address>{CONFIG.CHAIN?.toUpperCase()}</Address>
              <Mobile>
                <InfoWrap onClick={() => setActive(!active)}>
                  <IconInfo />
                  <DropDown isActive={active}>
                    <Address onClick={copy}>{getCopied()}</Address>
                    <Address>{metaunitBalance} MEU</Address>
                    <Address>{balance} ETH</Address>
                  </DropDown>
                </InfoWrap>
              </Mobile>
              <Desktop>
                <Address onClick={copy}>{getCopied()}</Address>
                <Address>{metaunitBalance} MEU</Address>
                <Address>{balance} ETH</Address>
              </Desktop>
              <PrimaryButton onClick={logout}>
                <Desktop>Logout</Desktop>
                <IconLogout stroke="#000" />
              </PrimaryButton>
            </Profile>
          ) : (
            <>
              <WrapBtn>
                <PrimaryButton
                  onClick={authenticate}
                  title={"Connect Wallet"}
                />
              </WrapBtn>
            </>
          )}
        </Flex>
      </Container>
    </Wrapper>
  );
};
export default Header;
