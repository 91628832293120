const getPureText = (value) => {
  if (!value) {
    return null;
  }

  const text = value?.toString();

  if (text.length > 13)
    return `${text.slice(0, 8)}...${text.slice(text.length - 4, text)}`;

  return text;
};
export default getPureText;
