import React from "react";
import { useFormContext } from "react-hook-form";
import lget from "lodash/get";
import { IconInfo } from "../../../../components/icons";
import { renderInputError } from "../../../../helpers/renderInputError";

import {
  Wrapper,
  InputWrap,
  Lable,
  Input,
  Title,
  Error,
  Recommended,
} from "./styles.js";

const HookInput = ({
  title,
  name,
  placeholder,
  lable,
  info,
  rules,
  value,
  step = 1,
  type,
  setForm,
  form,
  existLable = true,
  onChange,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const hasError = lget(errors, name);
  return (
    <Wrapper className="input-default-wrap">
      {title && (
        <Title title={title} className="input-default-title">
          {title}
        </Title>
      )}
      <InputWrap>
        {form ? (
          <Input
            step={step}
            error={hasError}
            placeholder={placeholder}
            lable={lable}
            type={type}
            value={value}
            onChange={onChange}
            {...register(name, {
              ...rules,
              onChange: (e) => {
                onChange(e);
                setForm({
                  ...form,
                  [name]:
                    lable && existLable
                      ? `${e.target.value} ${lable} `
                      : e.target.value,
                });
              },
            })}
            onWheel={(e) => e.target.blur()}
          />
        ) : (
          <Input
            step={step}
            error={hasError}
            placeholder={placeholder}
            lable={lable}
            type={type}
            value={value}
            onChange={onChange}
            {...register(name, {
              ...rules,
              onChange: onChange,
            })}
            onWheel={(e) => e.target.blur()}
          />
        )}

        {lable && <Lable className="input-default-lable">{lable}</Lable>}
      </InputWrap>
      {hasError
        ? hasError && (
            <Error className="input-error">
              {renderInputError(hasError, rules)}
            </Error>
          )
        : info && (
            <Recommended>
              <IconInfo stroke="#7E8390" />
              {info}
            </Recommended>
          )}
    </Wrapper>
  );
};
export default HookInput;
