import React, { useEffect, useState } from "react";
import { ColorButton, OneForm } from "../../../../components/ui";
import CONFIG from "../../../../config";
import { Wrapper, Title, Text, ButtonWrapp, IncentivesContent } from "../style";
import * as data from "./data.js";
import erc20 from "./abis/ERC20.json";
import abi from "./abis/incentive.json";
import useAuth from "../../../../helpers/auth";

const NFTIncentives = () => {
  const { user, loadingWeb3, web3 } = useAuth();
  const [fmBalance, setFmBalance] = useState(0);

  const load = async () => {
    const contract = new web3.eth.Contract(
      erc20,
      CONFIG.REACT_APP_META_UNIT_ADDRESS
    );
    const fm = await contract.methods
      .balanceOf(CONFIG.REACT_APP_METAUNIT_FM_INCENTIVE_ADDRESS)
      .call();

    setFmBalance(Math.round(fm / 10 ** 18));
  };

  const setCoeficient = (address) => async (data) => {
    const contract = new web3.eth.Contract(abi, address);
    await contract.methods
      .setCoeficient(data.amount)
      .call({ from: user.get("ethAddress") });
    await contract.methods
      .setCoeficient(data.amount)
      .send({ from: user.get("ethAddress") });
  };

  const withdrawWithAmount = (address) => async (data) => {
    const contract = new web3.eth.Contract(abi, address);
    await contract.methods
      .withdraw(BigInt(data.amount * 10 ** 18)) // eslint-disable-line
      .call({ from: user.get("ethAddress") });
    await contract.methods
      .withdraw(BigInt(data.amount * 10 ** 18)) // eslint-disable-line
      .send({ from: user.get("ethAddress") });
  };

  const transfer = (address) => async (data) => {
    const contract = new web3.eth.Contract(
      erc20,
      CONFIG.REACT_APP_META_UNIT_ADDRESS
    );
    await contract.methods
      .transfer(address, BigInt(data.amount * 10 ** 18)) // eslint-disable-line
      .call({ from: user.get("ethAddress") });
    await contract.methods
      .transfer(address, BigInt(data.amount * 10 ** 18)) // eslint-disable-line
      .send({ from: user.get("ethAddress") });
  };

  const withdrawAll = async (address) => {
    const contract = new web3.eth.Contract(abi, address);
    await contract.methods.withdraw().call({ from: user.get("ethAddress") }); // eslint-disable-line
    await contract.methods.withdraw().send({ from: user.get("ethAddress") }); // eslint-disable-line
  };

  useEffect(() => {
    if (!loadingWeb3) load();
  }, [loadingWeb3]);

  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <Text>{data.subtitle}</Text>
      <Text>{data.rules}</Text>
      <IncentivesContent>
        <Text>
          Balance of metaunit on First Mint Incentive: {fmBalance} MEU
        </Text>
      </IncentivesContent>
      <IncentivesContent>
        <OneForm
          title="Send MEU to First mint contact (mul 10^18)"
          name="amount"
          placeholder="Placeholder"
          rules={{ required: true }}
          submit={transfer(CONFIG.REACT_APP_METAUNIT_FM_INCENTIVE_ADDRESS)}
        />
      </IncentivesContent>
      <IncentivesContent>
        <OneForm
          title="Set coeficient to the First Mint Incentive contract"
          name="amount"
          placeholder="Placeholder"
          rules={{ required: true }}
          submit={setCoeficient(CONFIG.REACT_APP_METAUNIT_FM_INCENTIVE_ADDRESS)}
        />
      </IncentivesContent>
      <IncentivesContent>
        <OneForm
          title="Withdraw from the First Mint Incentive contract"
          name="amount"
          placeholder="Placeholder"
          rules={{ required: true }}
          submit={withdrawWithAmount(
            CONFIG.REACT_APP_METAUNIT_FM_INCENTIVE_ADDRESS
          )}
        />
      </IncentivesContent>
      <ButtonWrapp>
        <ColorButton
          onClick={() =>
            withdrawAll(CONFIG.REACT_APP_METAUNIT_FM_INCENTIVE_ADDRESS)
          }
          title="Withdraw all balance from First Mint Incentive contract"
        />
      </ButtonWrapp>
    </Wrapper>
  );
};

export default NFTIncentives;
