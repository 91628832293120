import React, { useState } from "react";
import { ColorButton, Input } from "../../../../components/ui";
import { Wrapper, Title, Text, Form, ButtonWrapp, InputList } from "../style";
import * as data from "./data.js";
import erc721 from "./abis/ERC721.json";
import useAuth from "../../../../helpers/auth";
import { FormProvider, useForm } from "react-hook-form";

const NFTIncentives = () => {
  const { user, web3 } = useAuth();
  const methods = useForm({ mode: "onChange" });
  const { reset, handleSubmit } = methods;
  const [form, setForm] = useState([
    { id: 1, token_address: "", to: "", token_id: "" },
  ]);

  const transfer = (batch) => (item) => {
    console.log(item, batch);
    const contract = new web3.eth.Contract(erc721, item.token_address);
    batch.add(
      contract.methods
        .transferFrom(item.to, item.token_id)
        .send({ from: user.get("ethAddress") })
    );
  };

  const addRow = () => {
    setForm((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        token_address: "",
        to: "",
        token_id: "",
      },
    ]);
  };

  const onChange = (item, name) => (e) => {
    setForm((prev) => {
      prev.find((cur) => cur.id === item.id)[name] = e.target.value;
      return prev;
    });
  };

  const onSubmit = async () => {
    try {
      const batch = new web3.BatchRequest();
      form.map(transfer(batch));
      await batch.execute();
      reset();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <Text>{data.subtitle}</Text>
      <Text>{data.rules}</Text>

      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {form.map((item) => (
            <InputList key={item.id}>
              <Input
                key={item.id}
                onChange={onChange(item, "token_address")}
                title="Token address"
                name={`token_address_${item.id}`}
              />
              <Input
                onChange={onChange(item, "to")}
                title="Send to"
                name={`to_${item.id}`}
              />
              <Input
                onChange={onChange(item, "token_id")}
                title="Token id"
                name={`token_id${item.id}`}
              />
            </InputList>
          ))}

          <ButtonWrapp>
            <ColorButton title="Add more" type="button" onClick={addRow} />
            <ColorButton title="Submit" type="submit" />
          </ButtonWrapp>
        </Form>
      </FormProvider>
    </Wrapper>
  );
};

export default NFTIncentives;
