import { useEffect, useState } from "react";
import Moralis from "../../../const/moralis";
import Web3 from "web3";
import CONFIG from "../../../config";
import { useSnackbar } from "notistack";
import isMobileAndTablet from "../../isMobile";
import erc20 from "../../../modules/home/sections/second-mint-incentive/abis/ERC20.json";

const provider = isMobileAndTablet() ? "walletconnect" : "metamask";

const useAuthProvider = () => {
  const [user, setUser] = useState(Moralis.User.current());
  const { enqueueSnackbar } = useSnackbar();
  const [balance, setBalance] = useState("0.0000");
  const [metaunitBalance, setMetaunitBalance] = useState("0.0000");
  const [web3, setWeb3] = useState();
  const [loadingWeb3, setLoadingWeb3] = useState(true);

  useEffect(() => {
    Moralis?.provider?.on("accountsChanged", async (accounts) => {
      if (
        user &&
        user.get("ethAddress")?.toLowerCase() !== accounts[0]?.toLowerCase()
      )
        enqueueSnackbar("Wrong account", { variant: "warning" });
    });
  }, [Moralis.provider]);

  useEffect(() => {
    Moralis?.provider?.on("networkChanged", (networkId) => {
      if (user && networkId !== CONFIG.CHAIN_ID?.toString())
        enqueueSnackbar("Wrong network", { variant: "warning" });
    });
  }, [Moralis.provider]);

  const updateBalance = async (address) => {
    await Moralis.start({ serverUrl: CONFIG.SERVER_URL, appId: CONFIG.APP_ID });
    const balance = parseFloat(
      (
        await Moralis.Web3API.account.getNativeBalance({
          chain: CONFIG.CHAIN,
          address
        })
      ).balance /
        10 ** 18
    ).toFixed(5);
    setBalance(balance);
  };

  const loadMetaunitbalance = async () => {
    const contract = new web3.eth.Contract(
      erc20,
      CONFIG.REACT_APP_META_UNIT_ADDRESS
    );

    const metaunit = await contract.methods
      .balanceOf(user.get("ethAddress"))
      .call();
    setMetaunitBalance(Math.round(metaunit / 10 ** 18));
  };

  useEffect(() => {
    if (!loadingWeb3) loadMetaunitbalance();
  }, [loadingWeb3]);

  useEffect(() => {
    const load = async () => {
      await Moralis.start({
        serverUrl: CONFIG.SERVER_URL,
        appId: CONFIG.APP_ID
      });
      if (user) {
        const options = {
          chain: CONFIG.CHAIN,
          address: user.get("ethAddress")
        };
        const balance = parseFloat(
          (await Moralis.Web3API.account.getNativeBalance(options)).balance /
            10 ** 18
        ).toFixed(5);
        setBalance(balance);
      }
    };
    load();
  }, [user]);

  useEffect(() => {
    if (user) {
      setLoadingWeb3(true);
      const load = async () => {
        try {
          await Moralis.enableWeb3({ provider });
          await Moralis.start({
            appId: CONFIG.APP_ID,
            serverUrl: CONFIG.SERVER_URL
          });
          const web3Js = new Web3(Moralis.provider);
          await updateBalance(user?.get("ethAddress"));
          setWeb3(web3Js);
          setLoadingWeb3(false);
        } catch (e) {}
      };
      load();
    }
  }, []);

  const changeNetwork = async (web3js) => {
    if ((await (web3js || web3).eth.getChainId()) !== CONFIG.CHAIN_ID)
      await Moralis.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${CONFIG.CHAIN_ID}` }]
      });
  };

  const authenticate = async () => {
    try {
      setLoadingWeb3(true);
      await Moralis.enableWeb3({ provider });
      const web3Js = new Web3(Moralis.provider);
      await changeNetwork(web3Js);
      const temp = await Moralis.authenticate({
        provider,
        signingMessage: "Welcome to Metaplayerone.app"
      });
      setWeb3(web3Js);
      setLoadingWeb3(false);
      setUser(temp);
      return temp;
    } catch (e) {
      console.log(e);
    }
  };

  const update = (temp) => {
    setUser(temp);
    updateBalance(temp?.get("ethAddress"));
  };

  const logout = async () => {
    Moralis.User.logOut();
    setUser();
  };

  return {
    user,
    balance,
    updateBalance,
    authenticate,
    update,
    logout,
    web3,
    loadingWeb3,
    changeNetwork,
    metaunitBalance
  };
};

export default useAuthProvider;
