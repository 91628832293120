import React from 'react';
import { useFormContext } from 'react-hook-form';
import lget from 'lodash/get';
import { renderInputError } from '../../../../helpers/renderInputError';

import { Wrapper, InputWrap, Input, Title, Error } from './styles.js';

const HookTextarea = ({
  title,
  name,
  placeholder,
  lable,
  rules,
  type,
  setForm,
  form,
  defaultValue,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const hasError = lget(errors, name);
  return (
    <Wrapper className="textarea-wrap">
      {title && <Title className="textarea-title">{title}</Title>}
      <InputWrap>
        {form ? (
          <Input
            error={hasError}
            placeholder={placeholder}
            lable={lable}
            type={type}
            {...register(name, {
              ...rules,
              onChange: (e) => {
                setForm({
                  ...form,
                  [name]: e.target.value,
                });
              },
            })}
          />
        ) : (
          <Input
            error={hasError}
            placeholder={placeholder}
            lable={lable}
            type={type}
            defaultValue={defaultValue ? defaultValue : null}
            {...register(name, {
              ...rules,
            })}
            onWheel={(e) => e.target.blur()}
          />
        )}
      </InputWrap>
      {hasError && <Error>{renderInputError(hasError, rules)}</Error>}
    </Wrapper>
  );
};
export default HookTextarea;
