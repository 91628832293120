import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
`;
export const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  position: fixed;
  background: #fff;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  stroke: #000;
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 24px;
  }
`;
export const Conatainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  backdrop-filter: blur(4px);
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: ${(props) =>
      props.white
        ? '#fff'
        : ' linear-gradient(180deg,#000000 0%,rgba(0,0,0,0.74) 100%)'};
  }
`;
export const Content = styled.div`
  width: 540px;
  height: fit-content;
  padding: 80px 0 60px 0;
  @media screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    padding: 32px 16px 80px 16px;
  }
`;
