import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Column = ({ children, p, m, width, alignItems, justifyContent }) => (
  <Wrapper
    style={{
      width: `${width}`,
      alignItems,
      justifyContent,
      padding: `${p}`,
      margin: `${m}`,
    }}
  >
    {children}
  </Wrapper>
);

export default Column;
