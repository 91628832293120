import React from 'react';
import styled from 'styled-components';
import NiceModal from '@ebay/nice-modal-react';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const Content = styled.main`
  width: 100%;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto auto auto;
`;

const Layout = () => {
  return (
    <NiceModal.Provider>
      <Wrapper>
        <Header />
        <Content>
          <Outlet />
        </Content>
        <Footer />
      </Wrapper>
    </NiceModal.Provider>
  );
};
export default Layout;
