import styled from "styled-components";

export const Wrapper = styled.header`
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 1000;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 768px) {
    height: 56px;
  }
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
  max-width: 1920px;
  @media screen and (max-width: 1440px) {
    padding: 0 64px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
`;
export const Logo = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 32px;
  color: #010101;
  & img {
    height: 32px;
  }
`;

export const WrapBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
`;

export const Profile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  @media screen and (max-width: 767px) {
    gap: 16px;

    & svg,
    img {
      margin-left: 0;
    }
  }
`;
export const Address = styled.button`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #2154fc;
  white-space: nowrap;
  & > svg {
    stroke: #2154fc;
    width: 12px;
    margin-left: 4px;
  }
`;
export const InfoWrap = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & > svg {
    stroke: #2154fc;
  }
`;
export const DropDown = styled.div`
  position: absolute;
  right: -40px;
  top: 54px;
  display: ${(props) => (props.isActive ? "flex" : "none")};
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 8px;
  gap: 8px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
`;
