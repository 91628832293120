import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 12px 0;
`;
export const InputWrap = styled.div`
  position: relative;
`;

export const Input = styled.textarea`
  width: 100%;
  height: 100px;
  background: #f7f7f7;
  border-radius: 6px;
  border: 1px solid ${(props) => (props.error ? '#E03636' : '#f7f7f7')};
  padding: 4px 16px;
  outline: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1a1919;
  resize: none;
  ::placeholder {
    color: ${(props) => (props.error ? '#E03636' : '#7E8390')};
  }
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7e8390;
  padding-bottom: 8px;
`;
export const Recommended = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #7e8390;
  padding-top: 4px;
  & svg {
    margin-right: 6px;
  }
`;
export const Error = styled(Recommended)`
  color: #e03636;
`;
export const Button = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
