const isDev =
  window.location.href.includes("dev") ||
  window.location.href.includes("localhost");
const isStage = window.location.href.includes("stage");

const common = {
  SERVER_URL: "https://nrnhzxloybjl.usemoralis.com:2053/server",
  APP_ID: "HhJtTqzu8ZZmrChihL0g5vRzgp5LyzswXIJ27qrj",
  EXAMPLE_ADDRESS: "0x55d398326f99059ff775485246999027b3197955",
};

const dev = {
  CHAIN_ID: "5",
  CHAIN: "goerli",
  PLATFORM_ADDRESS: "0x4D0b57c9c08C3c37F929490e2B7d1548EdF0a09E",
  COMMITTEE: [
    "0x88bf523C75Ff2F327934439625Aa0584D2cc5de6",
    "0x4D0b57c9c08C3c37F929490e2B7d1548EdF0a09E",
    "0xc5f24190Fd0f0C0e60470987f71E09c2474e782D",
    "0x7B16E2f3C5B91120D0aFe56EB013886dd953a3c0",
  ],
  REACT_APP_META_UNIT_ADDRESS: "0x9D63207D44efE1f936804c905166e2464283E791",
  REACT_APP_METAUNIT_TRACKER_ADDRESS:
    "0x0bABDD86ac132733ba75f0152b2c9E33C61fCA78",
  REACT_APP_METAUNIT_DAO_INCENTIVE_ADDRESS:
    "0x4D9cF7d77ab56041c946f97EA8b72c74F5c9e1ed",
  REACT_APP_METAUNIT_FUND_ADDRESS: "0x54d9F131b375Cc01F0bdf45DB0D1A461Ac255d82",
  REACT_APP_METAUNIT_ALLOCATION_ADDRESS:
    "0xC1FF0c034793A3e3Ae8E5301495dC3E503358409",
  REACT_APP_DAO_FACTORY_ADDRESS: "0x85346a0C062b3FA4584E67320F4679A7A3389832",
  REACT_APP_DAO_AIRDROP_ADDRESS: "0xF2d5cC6BDF98B4FEe11F2D4ea64DD53ED44e359f",
  REACT_APP_DAO_ALLOCATION_ADDRESS:
    "0x4d404CEE5933733D65e454c84a13B5088d2c2b15",
  REACT_APP_DAO_STAKING_ADDRESS: "0xD433Ce42326C9D98Fcb614f84B343E7a02558be9",
  REACT_APP_DAO_PROJECT_FACTORY_ADDRESS:
    "0x8F133eD500f522378920e9F0E89832467244E437",
  REACT_APP_DAO_GOVERNANCE_ADDRESS:
    "0xc06C8A1B2BF38F1505b35962BDb28a79855f8f04",
  REACT_APP_SELECTIVE_GALLERY_ADDRESS:
    "0x94A3Cb81Ab6507237734B9842110f94b0559a498",
  REACT_APP_GENERATIVE_GALLERY_ADDRESS:
    "0xA379bE750C239DD3B174FEafaAA14Cb5F523E649",
  REACT_APP_COLLECTION_STAKING_ADDRESS:
    "0x60748d09340289AaeBd72E0d10d6753046ed4bBc",
  REACT_APP_COLLECTION_AIRDROP_ADDRESS:
    "0x90eE52aDaa332e149b8Eca690F9F7dEA25fD0A87",
  REACT_APP_SINGLE_TOKEN_ADDRESS: "0x653f9c6AC691E907bF121d814926Eac1A613c92A",
  REACT_APP_MULTIPLE_TOKEN_ADDRESS:
    "0xd7F08770B445F1c0917D6e8925c67eA0Bf61dc91",
  REACT_APP_SINGLE_TOKEN_INSTANT_SALE_ADDRESS:
    "0x879A41f76163dA0B7F756f2550d192Cb53100174",
  REACT_APP_MULTIPLE_TOKEN_INSTANT_SALE_ADDRESS:
    "0x18A45502d927F55647A79E8713EB7312a5E8FdcA",
  REACT_APP_SINGLE_TOKEN_AUCTION_ADDRESS:
    "0xcA5F0dC1773b0446d01036BC953850d85d348cf4",
  REACT_APP_MULTIPLE_TOKEN_AUCTION_ADDRESS:
    "0x1E1AB5215ea698a7E383eb4C44D9A28cF5E02EFA",
  REACT_APP_MAKE_AN_OFFER_ADDRESS: "0xB653b054FaA280C85C4C7f050994C70AE1dc4260",
  REACT_APP_METASPACE_FACTORY_ADDRESS:
    "0x73D9a9A845839Ab38e447cE342a66Cf756FB4810",
  REACT_APP_MARKETPLACE_FACTORY_ADDRESS:
    "0xB9151103Fd5Fd40dE1e475A0Cc2A46A488a3ECDB",
  REACT_APP_PROMOTION_ADDRESS: "0xf2015c449d84D824A939133d1d1303F8124DF5A7",
  REACT_APP_METASKYNET_SHARD_CRYSTAL_ADDRESS:
    "0xC40470126a452540a068116729edF8bEed3fCAd5",
  REACT_APP_CRYSTAL_OF_PROMOTION_ADDRESS:
    "0x177F1F36A91Ada933A1311F60Cd4b1B6B09d6f96",
  REACT_APP_CRYSTAL_OF_METAPLAYER_ADDRESS:
    "0xF43af662FaBAc90F01e704f06ACB0b15f41E1ce4",
  REACT_APP_CRYSTAL_OF_GENERATIVE_POWER_ADDRESS:
    "0x3AfD4ade35ff39B53afddd6956F0D436888D0749",
  REACT_APP_CRYSTAL_OF_ALLOCATION_VISION_POWER_ADDRESS:
    "0x82427b3f9AAA7791E558312dA016D0ADC51afCDd",
  REACT_APP_CRYSTAL_OF_AIRDROP_POWER_ADDRESS:
    "0x96Fc2b846703925400BD31ec488F12E3A6c11772",
  REACT_APP_AIRLOVE_ADDRESS: "0xb16BD12a7dCd3F13470F180cee872134f0552f1A",
  REACT_APP_GOOVY_ADDRESS: "0x95D6490BF388858A370656f9238D4456B1e47b8a",
  REACT_APP_LEXOR_ADDRESS: "0x24B7aAF2af5d6852d9B73680A17E87dfcBec6bFf",
  REACT_APP_PLAYER_ADDRESS: "0x65E59E1e21c80Cdac91769c8361A28dDe254B4Ce",
  REACT_APP_VIOS_ADDRESS: "0xd5F01F9EdFd1B6d5b15C5575c0552dfA49FAe84D",
  REACT_APP_METAUNIT_FM_INCENTIVE_ADDRESS:
    "0xcdc4E8a6728acb83F583E7f875a53b78EC725eBe",
  REACT_APP_METAUNIT_SM_INCENTIVE_ADDRESS:
    "0xE39D360B886cC7562C5A525987989258742C7baA",
  REACT_APP_METAUNIT_WHITELIST_IDO:
    "0xd59da7ade966Ca7Ee18CF71C2C0c948A0A0117Ce",
  REACT_APP_FEE_STORAGE_ADDRESS: "0x49D90d5A5fD707B25Fc670572C5086E36d2C4C75",
};

const stage = {
  CHAIN_ID: "1",
  CHAIN: "mainnet",
  REACT_APP_META_UNIT_ADDRESS: "0x6E975115250B05C828ecb8edeDb091975Fc20a5d",
  REACT_APP_METAUNIT_TRACKER_ADDRESS:
    "0x8d0f571be24172ab2f28b186e0c5d5a2633b9c30",
  REACT_APP_METAUNIT_NFT_INCENTIVE_ADDRESS:
    "0x055Ea6D5bcA16b6Ed5671bCdDA7388D7aE06d278",
  REACT_APP_METAUNIT_DAO_INCENTIVE_ADDRESS:
    "0x128223b31f8b0Cc52aE4548fe6F948a69c817ffF",
  REACT_APP_METAUNIT_FUND_ADDRESS: "0xf75b8EFBFE833EA643d9b2664cD8d17dE3875557",
  REACT_APP_METAUNIT_ALLOCATION_ADDRESS:
    "0x53C78218a9424F134050C9DfdB1223b87F7D8eB1",

  REACT_APP_DAO_FACTORY_ADDRESS: "0x7146AB9f1d9b053455bC9C0c1edc45243B503633",
  REACT_APP_DAO_AIRDROP_ADDRESS: "0xaBe4644f446c879c1DD3a0709C5A740937C237Da",
  REACT_APP_DAO_ALLOCATION_ADDRESS:
    "0x7ADe84F100E0970B7AA39A12064d8C78110E79d7",
  REACT_APP_DAO_STAKING_ADDRESS: "0xca26B38e56f61806D29702b0f10E0Ba7FC24eF8B",
  REACT_APP_DAO_PROJECT_FACTORY_ADDRESS:
    "0x5eDF31fbD363315389785AcE20FC6963ef9115Fc",
  REACT_APP_DAO_GOVERNANCE_ADDRESS:
    "0x382997aE97B1ba6094Ef5618b74266d5743A8c8b",

  REACT_APP_SELECTIVE_GALLERY_ADDRESS:
    "0x5C34fda4834DbFD9833C00ceD63DaD93410C561d",
  REACT_APP_GENERATIVE_GALLERY_ADDRESS:
    "0x1fd8082A92EA3aD21cD5CE29Fb04389F4B26e89E",
  REACT_APP_COLLECTION_STAKING_ADDRESS:
    "0x2Cb76E77e01f3d5713ca0789D18bDD85a6f624Fc",
  REACT_APP_COLLECTION_AIRDROP_ADDRESS:
    "0xBE2Cd051e9b99E178B8dF158fc827412cCFb0f39",

  REACT_APP_SINGLE_TOKEN_ADDRESS: "0x1fd6aA3eCBa687D61Db6922896fE80aE7EB05771",
  REACT_APP_MULTIPLE_TOKEN_ADDRESS:
    "0x2fd89E3df44c15ada730dCe625d672a26fFD3399",
  REACT_APP_SINGLE_TOKEN_INSTANT_SALE_ADDRESS:
    "0xF68fFdB139c27B01a347531F7ad1857E51458d98",
  REACT_APP_MULTIPLE_TOKEN_INSTANT_SALE_ADDRESS:
    "0x3a031c930DB877f7b19d909434B70511c9D50366",
  REACT_APP_SINGLE_TOKEN_AUCTION_ADDRESS:
    "0xD84d7cD67566207FE236969507FB2E9dc2fe7219",
  REACT_APP_MULTIPLE_TOKEN_AUCTION_ADDRESS:
    "0x22F9501D1c76867B9A8A8115b7639b1fDcA34647",
  REACT_APP_MAKE_AN_OFFER_ADDRESS: "0x8917c7f706d4870ee5ecb809a44e0a16a454db00",

  REACT_APP_METASPACE_FACTORY_ADDRESS:
    "0x325960d55b651Ecc367988fad89452439f1C5aF5",
  REACT_APP_MARKETPLACE_FACTORY_ADDRESS:
    "0x57a3de9f6a7c02c8c86fbf29d9b61cbede39015d",

  REACT_APP_PROMOTION_ADDRESS: null,

  REACT_APP_METASKYNET_SHARD_CRYSTAL_ADDRESS:
    "0x72771cfac63092255ab9a7ec03129f0574844709",
  REACT_APP_CRYSTAL_OF_PROMOTION_ADDRESS:
    "0x434D6209881B11b9E3B0B988805Bf9Ec71f15556",
  REACT_APP_CRYSTAL_OF_METAPLAYER_ADDRESS: null,
  REACT_APP_CRYSTAL_OF_GENERATIVE_POWER_ADDRESS: null,
  REACT_APP_CRYSTAL_OF_ALLOCATION_VISION_POWER_ADDRESS: null,
  REACT_APP_CRYSTAL_OF_AIRDROP_POWER_ADDRESS: null,
  REACT_APP_AIRLOVE_ADDRESS: null,
  REACT_APP_GOOVY_ADDRESS: null,
  REACT_APP_LEXOR_ADDRESS: null,
  REACT_APP_PLAYER_ADDRESS: null,
  REACT_APP_VIOS_ADDRESS: null,
};

const prod = {
  CHAIN_ID: "1",
  CHAIN: "mainnet",
  PLATFORM_ADDRESS: "0x8e66691515BaDbACA6EE686D74C8Ef29e1aFE57F",
  COMMITTEE: [
    "0x0c119114f1A8240457903a1C64E4919be138F86d",
    "0x8e66691515BaDbACA6EE686D74C8Ef29e1aFE57F",
    "0x5F176d0db1C88075E6e8172934c51E6D3c7f73b8",
    "0x8f34084f8A1882ab4D3efB374FcEc1Ecc8A40bc1",
    "0xd4Ffd865d86fbEA88658dc76Ab85ef80ef52E683",
  ],
  REACT_APP_META_UNIT_ADDRESS: "0x6E975115250B05C828ecb8edeDb091975Fc20a5d",
  REACT_APP_METAUNIT_TRACKER_ADDRESS:
    "0x3adf09d2D5292ADc2874B2c867feCbB3b0b0b174",
  REACT_APP_METAUNIT_NFT_INCENTIVE_ADDRESS:
    "0x055Ea6D5bcA16b6Ed5671bCdDA7388D7aE06d278",
  REACT_APP_METAUNIT_DAO_INCENTIVE_ADDRESS:
    "0xa9aDBc3C188ea45Ff7ef3DBCaA103CD38E9A47E0",
  REACT_APP_METAUNIT_FUND_ADDRESS: "0xf75b8EFBFE833EA643d9b2664cD8d17dE3875557",
  REACT_APP_METAUNIT_ALLOCATION_ADDRESS:
    "0x53C78218a9424F134050C9DfdB1223b87F7D8eB1",
  REACT_APP_RANDOMIZER_ADDRESS: "0x153862192896f2a5a9af3a140ec35c0b395c14b9",
  REACT_APP_DAO_FACTORY_ADDRESS: "0x7146AB9f1d9b053455bC9C0c1edc45243B503633",
  REACT_APP_DAO_AIRDROP_ADDRESS: "0xaBe4644f446c879c1DD3a0709C5A740937C237Da",
  REACT_APP_DAO_ALLOCATION_ADDRESS:
    "0x7ADe84F100E0970B7AA39A12064d8C78110E79d7",
  REACT_APP_DAO_STAKING_ADDRESS: "0xca26B38e56f61806D29702b0f10E0Ba7FC24eF8B",
  REACT_APP_DAO_PROJECT_FACTORY_ADDRESS:
    "0x5eDF31fbD363315389785AcE20FC6963ef9115Fc",
  REACT_APP_DAO_GOVERNANCE_ADDRESS:
    "0x382997aE97B1ba6094Ef5618b74266d5743A8c8b",
  REACT_APP_SELECTIVE_GALLERY_ADDRESS:
    "0x86A2f4C314378A2062aF529a81fDD6d167F0879E",
  REACT_APP_GENERATIVE_GALLERY_ADDRESS:
    "0x5EB62D080BcDEd8d3F394eDd4232E7f9B95Ac6A4",
  REACT_APP_COLLECTION_STAKING_ADDRESS:
    "0x2Cb76E77e01f3d5713ca0789D18bDD85a6f624Fc",
  REACT_APP_COLLECTION_AIRDROP_ADDRESS:
    "0xBE2Cd051e9b99E178B8dF158fc827412cCFb0f39",
  REACT_APP_SINGLE_TOKEN_ADDRESS: "0x1fd6aA3eCBa687D61Db6922896fE80aE7EB05771",
  REACT_APP_MULTIPLE_TOKEN_ADDRESS:
    "0x2fd89E3df44c15ada730dCe625d672a26fFD3399",
  REACT_APP_SINGLE_TOKEN_INSTANT_SALE_ADDRESS:
    "0x3ee904d0F3B31c0940f72a198781D77fC34f770A",
  REACT_APP_MULTIPLE_TOKEN_INSTANT_SALE_ADDRESS:
    "0x688f8F0470425B3810787d0Ad86Cf230e67EA74C",
  REACT_APP_SINGLE_TOKEN_AUCTION_ADDRESS:
    "0xb7590acaACfbD1CFe6B0019F81FAbf6c2E07A172",
  REACT_APP_MULTIPLE_TOKEN_AUCTION_ADDRESS:
    "0x731e8B22b50b16533D9a355BfD5116eD9a827840",
  REACT_APP_MAKE_AN_OFFER_ADDRESS: "0x8917c7f706d4870ee5ecb809a44e0a16a454db00",
  REACT_APP_METASPACE_FACTORY_ADDRESS:
    "0x325960d55b651Ecc367988fad89452439f1C5aF5",
  REACT_APP_MARKETPLACE_FACTORY_ADDRESS:
    "0x57a3de9f6a7c02c8c86fbf29d9b61cbede39015d",
  REACT_APP_PROMOTION_ADDRESS: null,
  REACT_APP_METASKYNET_SHARD_CRYSTAL_ADDRESS:
    "0x72771cfac63092255ab9a7ec03129f0574844709",
  REACT_APP_CRYSTAL_OF_PROMOTION_ADDRESS:
    "0x434D6209881B11b9E3B0B988805Bf9Ec71f15556",
  REACT_APP_CRYSTAL_OF_METAPLAYER_ADDRESS: null,
  REACT_APP_CRYSTAL_OF_GENERATIVE_POWER_ADDRESS: null,
  REACT_APP_CRYSTAL_OF_ALLOCATION_VISION_POWER_ADDRESS: null,
  REACT_APP_CRYSTAL_OF_AIRDROP_POWER_ADDRESS: null,
  REACT_APP_AIRLOVE_ADDRESS: null,
  REACT_APP_GOOVY_ADDRESS: null,
  REACT_APP_LEXOR_ADDRESS: null,
  REACT_APP_PLAYER_ADDRESS: null,
  REACT_APP_VIOS_ADDRESS: null,
  REACT_APP_METAUNIT_FM_INCENTIVE_ADDRESS:
    "0x88d288C5A634710d930e7f98F7A3c8fd903ac413",
  REACT_APP_METAUNIT_SM_INCENTIVE_ADDRESS:
    "0x6602DcEcD720a9e651B023875A13b968CEA21865",
  REACT_APP_FEE_STORAGE_ADDRESS: "0xDE38A0ea6308793eC1DeAAF1Daa2a9fe56e11c66",
};

let CONFIG = {};

if (isDev) CONFIG = { ...common, ...dev };
else if (isStage) CONFIG = { ...common, ...stage };
else CONFIG = { ...common, ...prod };

export default CONFIG;
