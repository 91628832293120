import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  max-width: 1920px;
  width: 100%;
  height: 100%;
  padding: 0 80px;
  position: relative;
  z-index: 2;
  background-color: ${(props) => (props.white ? '#fff' : 'transparent')};
  @media screen and (max-width: 1440px) {
    padding: 0 64px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
`;

const Container = ({ children, white }) => (
  <Wrapper white={white}>{children}</Wrapper>
);

export default Container;
