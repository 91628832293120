import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 8px 16px;
  gap: 8px;
  background: linear-gradient(99.78deg, #9f0bee 0.69%, #145bfd 98.32%);
  border-radius: 8px;
  transition: all 0.2s linear;
  color: #fff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  & > svg {
    stroke: #fff;
  }
  &:hover {
    box-shadow: 0px 4px 16px rgb(86 54 247 / 30%);
  }
`;

const Button = ({ title, children, onClick, type }) => {
  return (
    <Wrapper onClick={onClick} type={type || 'button'} className="color_btn">
      {children}
      {title}
    </Wrapper>
  );
};
export default Button;
