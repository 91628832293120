export const tabs = [
  {
    title: 'All Contracts',
    value: 'all-contracts',
  },
  {
    title: 'Market Contracts',
    value: 'market',
  },
  {
    title: 'MetaUnit Whitelist',
    value: 'metaunit-whitelist',
  },
  {
    title: 'First mint incentive',
    value: 'first-mint-incentive',
  },
  {
    title: 'Second mint incentive',
    value: 'second-mint-incentive',
  },
  {
    title: 'DAO incentive',
    value: 'dao-incentive',
  },
  {
    title: 'Bulk transfer erc20',
    value: 'bulk-transfer-erc20',
  },
  {
    title: 'Bulk transfer erc721',
    value: 'bulk-transfer-erc721',
  },
  {
    title: 'Bulk transfer erc1155',
    value: 'bulk-transfer-erc1155',
  },
];
