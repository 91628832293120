import React from "react";
import { useParams, useNavigate } from "react-router";
import { Container } from "../../components/containers";
import {
  Market,
  MetaUnitWhitelist,
  AllContractsList,
  DAOIncentives,
  FirstMintIncentive,
  SecondMintIncentive,
  Erc20transfer,
  Erc721transfer,
  Erc1155transfer
} from "./sections";
import { tabs } from "./data";
import { Wrapper, Tabs, Tab, Content } from "./style";

const Home = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const switchTab = (option) => {
    navigate(`/${option?.value}`);
    window.scrollTo(0, 0);
  };

  const getContent = () => {
    switch (tab) {
      case "all-contracts":
        return <AllContractsList />;
      case "market":
        return <Market />;
      case "metaunit-whitelist":
        return <MetaUnitWhitelist />;
      case "dao-incentive":
        return <DAOIncentives />;
      case "first-mint-incentive":
        return <FirstMintIncentive />;
      case "second-mint-incentive":
        return <SecondMintIncentive />;
      case "bulk-transfer-erc20":
        return <Erc20transfer />;
      case "bulk-transfer-erc721":
        return <Erc721transfer />;
      case "bulk-transfer-erc1155":
        return <Erc1155transfer />;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <Container>
        <Tabs>
          {tabs.map((option) => (
            <Tab
              key={option?.title}
              active={tab === option?.value}
              onClick={() => switchTab(option)}
            >
              {option.title}
            </Tab>
          ))}
        </Tabs>
        <Content>{getContent()}</Content>
      </Container>
    </Wrapper>
  );
};

export default Home;
