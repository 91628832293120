import CONFIG from "./config";
import Router from "./router";
import { ProvideAuth } from "./helpers/auth/provider/ProvideAuth";
import { MoralisProvider } from "react-moralis";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <SnackbarProvider>
      <ProvideAuth>
        <MoralisProvider appId={CONFIG.APP_ID} serverUrl={CONFIG.SERVER_URL}>
          <Router />
        </MoralisProvider>
      </ProvideAuth>
    </SnackbarProvider>
  );
}

export default App;
