import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 12px 0;
  .css-b62m3t-container {
    & > div {
      width: 100%;
      min-height: 48px;
      background-color: #f7f7f7;
      border: 1px solid ${(props) => (props.error ? '#e03636' : '#fff')};
      box-sizing: border-box;
      border-radius: 6px;
      box-shadow: none;
      cursor: pointer;
      &:hover {
        border: 1px solid #fff;
      }
    }
    .css-1pahdxg-control {
      width: 100%;
      min-height: 48px;
      background-color: #f7f7f7;
      border: 1px solid #fff;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 0 0 0 16px;
      box-shadow: none;
      cursor: pointer;
      &:hover {
        border: 1px solid #fff;
      }
      .css-319lph-ValueContainer {
        padding: 0;
        .css-qc6sy-singleValue {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #1a1919;
        }
      }
      .css-1hb7zxy-IndicatorsContainer {
        .css-1okebmr-indicatorSeparator {
          width: 0;
        }
        .css-tlfecz-indicatorContainer {
          svg {
            fill: #7e8390;
          }
        }
      }
    }
    .css-1s2u09g-control {
      width: 100%;
      min-height: 48px;
      background-color: #f7f7f7;
      border: 1px solid #fff;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 0 0 0 16px;
      cursor: pointer;
      box-shadow: none;
      &:hover {
        border: 1px solid #fff;
      }
      .css-319lph-ValueContainer {
        padding: 0;
        .css-qc6sy-singleValue {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #1a1919;
        }
      }
      .css-1hb7zxy-IndicatorsContainer {
        .css-1okebmr-indicatorSeparator {
          width: 0;
        }
        .css-tlfecz-indicatorContainer {
          svg {
            fill: #7e8390;
          }
        }
      }
    }
    .css-26l3qy-menu {
      padding: 4px 2px 4px 0;
      box-shadow: 0px 20px 50px rgb(0 0 0 / 5%);
      .css-4ljt47-MenuList {
        & div {
          background-color: #fff;
          color: #7e8390;
          padding-left: 16px;
          cursor: pointer;
          &:hover {
            color: #1a1919;
          }
        }
        .css-9gakcf-option {
          color: #1a1919;
          font-weight: 500;
        }
      }
    }
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7e8390;
  padding-bottom: 8px;
`;
export const Error = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #e03636;
  padding-top: 4px;
  & svg {
    margin-right: 6px;
  }
`;
