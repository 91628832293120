import CONFIG from "../../../../config";
import getPureText from "../../../../helpers/getPureText";

export const title = "MetaUnit Whitelist";

export const subtitle = `Here you can submit new addresses to MEU Whitelist (${getPureText(
  CONFIG.REACT_APP_METAUNIT_WHITELIST_IDO_ADDRESS
)})`;

export const rules = `\nRules:\n1. Each address must be an ether address (ex. ${
  CONFIG.EXAMPLE_ADDRESS
});\n2. Each address must be separated by a comma (ex. ${getPureText(
  CONFIG.EXAMPLE_ADDRESS
)}, ${getPureText(CONFIG.EXAMPLE_ADDRESS)}, ${getPureText(
  CONFIG.EXAMPLE_ADDRESS
)});\n3. In the case of successful execution, 1 transaction should be called and a pop-up with the inscription "Success" should appear.`;
