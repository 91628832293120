import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useForm, FormProvider } from "react-hook-form";
import abi from "./abis/MetaUnitTracker.json";
import * as data from "./data";
import { ColorButton } from "../../../../components/ui";
import CONFIG from "../../../../config";
import useAuth from "../../../../helpers/auth";
import { IconDelete, IconAddCircle } from "../../../../components/icons";
import { Textarea } from "../../../../components/ui";
import { Wrapper, Title, Text, Form, ButtonWrapp } from "../style";

const Market = () => {
  const { user, web3, changeNetwork } = useAuth();
  const [action, setAction] = useState();
  const methods = useForm({ mode: "onChange" });
  const { enqueueSnackbar } = useSnackbar();
  const { reset, handleSubmit } = methods;

  const onSubmit = async (data) => {
    try {
      await changeNetwork(web3);
      console.log("action", data, action);
      const params = data.address.replaceAll(" ", "").split(",");
      console.log(params);
      const contract = new web3.eth.Contract(
        abi,
        CONFIG.REACT_APP_METAUNIT_TRACKER_ADDRESS
      );
      await contract.methods
        .setSaleContractsAddresses(params, action)
        .call({ from: user?.get("ethAddress") });
      await contract.methods
        .setSaleContractsAddresses(params, action)
        .send({ from: user?.get("ethAddress") });
      reset();
      enqueueSnackbar("Success", { variant: "success" });
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Wrapper>
      <Title>{data.title}</Title>
      <Text>{data.subtitle}</Text>
      <Text>{data.rules}</Text>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Textarea
            title='List of contract addresses (should be splited by comma - " , ")'
            name="address"
            placeholder="0x7B16E2f3C5B91120D0aFe56EB013886dd953a3c0, 0xc5f24190Fd0f0C0e60470987f71E09c2474e782D"
            rules={{ required: true }}
          />
          <ButtonWrapp>
            <ColorButton
              title={data.submit}
              type="submit"
              onClick={() => setAction(true)}
            >
              <IconAddCircle />
            </ColorButton>
            <ColorButton
              title={data.remove}
              type="submit"
              onClick={() => setAction(false)}
            >
              <IconDelete />
            </ColorButton>
          </ButtonWrapp>
        </Form>
      </FormProvider>
    </Wrapper>
  );
};
export default Market;
