import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  & > button {
    padding: 12px;
    margin-top: 8px;
  }
`;
