import React from "react";
import CONFIG from "../../../../config";
import { Wrapper, Title, Text } from "../style";

const formatString = (str) => {
  const arr = str
    .replaceAll("REACT_APP_", "")
    .replaceAll("_ADDRESS", "")
    .replaceAll("_", " ")
    .toLowerCase()
    .split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join("");
};

const formatAddress = (str) => {
  return typeof str?.toLowerCase == "function"
    ? str?.toLowerCase()
    : str?.map((item) => `${item?.toLowerCase()} `);
};

const AllContractsList = () => {
  return (
    <Wrapper>
      <Title>List Of All contracts</Title>
      <Text>
        {Object.keys(CONFIG).map((item) => (
          <Text>
            {formatString(item)}:{"    "}
            {formatAddress(CONFIG[item])}
          </Text>
        ))}
      </Text>
    </Wrapper>
  );
};

export default AllContractsList;
