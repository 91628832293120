import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: block;
  min-height: 100vh;
  z-index: 1;
  padding-top: 100px;
`;
export const Tabs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`;
export const Tab = styled.button`
  border-radius: 6px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px;
  white-space: nowrap;
  background: ${(props) => (props.active ? '#F3F6FE' : '#fff')};
  color: ${(props) => (props.active ? '#2154FC' : '#010101')};
  border: 1px solid ${(props) => (props.active ? '#2154FC' : '#d8d8d8')};
  &:hover {
    border: 1px solid #2154fc;
    color: #2154fc;
  }

  @media screen and (max-width: 767px) {
    &:hover {
      color: ${(props) => (props.active ? '#2154FC' : '#7E8390')};
      border: 1px solid ${(props) => (props.active ? '#F3F6FE' : '#d8d8d8')};
    }
  }
`;
export const Content = styled.div`
  width: 100%;
  display: block;
  padding: 32px 0;
`;
