import React from 'react';
import styled from 'styled-components';
import { useRouteError } from 'react-router-dom';
import { Column, Container } from '../../components/containers';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: linear-gradient(101.32deg, #c3e8f0 0%, #00819e 94.32%);
`;
export const Title = styled.h1`
  width: 80%;
  text-align: center;
  padding-bottom: 32px;
  color: #fff;
`;
export const Text = styled.h3`
  width: 80%;
  text-align: center;
  color: #fff;
`;
const ErrorPage = () => {
  const error = useRouteError();
  return (
    <Wrapper>
      <Container>
        <Column alignItems={'center'}>
          <Title>Error</Title>
          <Text>{error.statusText || error.message}</Text>
        </Column>
      </Container>
    </Wrapper>
  );
};

export default ErrorPage;
