import React from "react";
import { useSnackbar } from "notistack";
import { useForm, FormProvider } from "react-hook-form";
import { ColorButton } from "..";
import { Input } from "..";
import { patterns } from "../../../helpers/validatePattern";
import { Form, ButtonWrapp } from "./style";
import useAuth from "../../../helpers/auth";

const OneForm = ({ submit, title, name, placeholder, rules, titleBtn, onChange }) => {
  const methods = useForm({ mode: "onChange" });
  const { enqueueSnackbar } = useSnackbar();
  const { changeNetwork } = useAuth()

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data) => {
    try {
    await changeNetwork();
    await submit(data);
      reset();
      enqueueSnackbar('Success', { variant: 'success' })
    } catch (e) {
      console.error(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          title={title}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          rules={rules}
        />
        <ColorButton title={titleBtn || "Send"} type="submit" />
      </Form>
    </FormProvider>
  );
};

export default OneForm;
