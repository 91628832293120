import React from "react";
import styled from "styled-components";
import { IconCheck } from "../../../icons";

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  pointer-events: ${(props) => (props.disable ? "none" : "all")};
  opacity: ${(props) => (props.disable ? "0.5" : "1")};
  background: ${(props) =>
    props.active
      ? "linear-gradient(99.78deg, #9f0bee 0.69%, #145bfd 98.32%)"
      : "transparent"};
  border: 1px solid ${(props) => (props.active ? "transparent" : "#7E8390")};
  border-radius: 2px;
  & > svg {
    stroke: #fff;
  }
`;
const CheckBox = ({ onClick, active, disable }) => {
  return (
    <Wrapper onClick={onClick} active={active} type="button" disable={disable}>
      {active && <IconCheck />}
    </Wrapper>
  );
};
export default CheckBox;
